.loader {
    left: 0;
    top: 0;
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .loaderText {
    font-size: x-large;
    text-align: center;
  }
  
  .loaderAnimation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
  
  .loading {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    animation-name: stretch;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
  }
  
  .invisible {
    display: none;
  }

  @keyframes stretch {
    0% {
      transform: scale(0.6);
    }
    100% {
      transform: scale(2);
    }
  }