
@keyframes leftToRight {
   0% {left: 18%; top: 45%; transform: rotate(0deg); position: absolute;}
    60% {left: 60%; top: 20% ; transform: rotate(3600deg);position: absolute;}
    100% {left: 89%; top: 45%; transform: rotate(0deg);position: absolute;}
}

.rotateOnHover {
    transition: transform .6s ease-in-out;
  }
.rotateOnHover:hover {
    transform: rotate(360deg);
  }